import React, { useState } from 'react'
import styled from "styled-components";
import Sound from 'react-sound';
import song from '../files/O-Chainis.m4a';
import { IconButton } from '@mui/material'
import { VolumeOff, VolumeUp } from '@mui/icons-material';

const StyledButton = styled(IconButton)(({ theme }) => ({
  ":hover": { color: theme.palette.typography.gold },
  color: theme.palette.typography.grey,
  position: "fixed",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}))

export const PlaySound = (
) => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <div>
      <StyledButton onClick={() => setIsPlaying(!isPlaying)}>{!isPlaying ? <VolumeOff /> : <VolumeUp />}
      </StyledButton>
      <Sound
        url={song}
        playStatus={
          isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED
        }
        playFromPosition={300}
      />
    </div>
  )
}
