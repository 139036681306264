import React from 'react'
import styled from "styled-components";
import { Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledGrid = styled(Grid)(({ theme, desktopView }) => ({
  width: "70%",
  marginLeft: 'auto',
  marginRight: 'auto',
  justifyContent: "center",
  color: theme.palette.typography.white,
  paddingBottom: desktopView ? "12.5%" : "45%",
  "g": { color: theme.palette.typography.gold },
  "gr": { color: theme.palette.typography.grey },
  "w": { color: theme.palette.typography.white },
  ".title": {
    fontSize: desktopView ? "2.5em" : "1.7em", fontWeight: "500", paddingBottom: '5%'
  },
  ".faq": {
    fontSize: desktopView ? "1.5em" : "0.9em"
  }
}))

export const FAQ = () => {
  const desktopView = useMediaQuery('(min-width:600px)');
  return (
      <StyledGrid container desktopView={desktopView}>
        <Grid item className="title">
          FAQ
        </Grid>
        <Grid item className="faq">
          <Accordion style={{ backgroundColor: "#4a4a4a", color: "#e1e1e1" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              What does philotimo mean?
            </AccordionSummary>
            <AccordionDetails>
              <g>Philotimo, or φιλότιμο, has no direct English translation. For millennia the Greeks have used Philotimo to describe a sense of honor so deep that it goes beyond oneself. This is what it means to be a true warrior.</g>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#4a4a4a", color: "#e1e1e1" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              Wen mint?
            </AccordionSummary>
            <AccordionDetails>
              <g>To be announced.</g>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#4a4a4a", color: "#e1e1e1" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              How much is mint?
            </AccordionSummary>
            <AccordionDetails>
              <g>To be announced.</g>
            </AccordionDetails>
          </Accordion >
          <Accordion style={{ backgroundColor: "#4a4a4a", color: "#e1e1e1" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              What are the fight mechanics?
            </AccordionSummary>
            <AccordionDetails>
              <g>More information on how to play can be found under “Fight.”</g>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#4a4a4a", color: "#e1e1e1" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              Can I trade my warrior during the tournament?
            </AccordionSummary>
            <AccordionDetails>
              <g>Yes! We encourage trading throughout the tournament. As your warrior progresses through the rounds, their associated value increases. Do you play it safe and sell your warrior early despite their increased chances of taking home spoils of war? Or do you risk it all in a fight for philotimo...</g>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#4a4a4a", color: "#e1e1e1" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              My warrior was eliminated, but I still want to be a part of the tournament!
            </AccordionSummary>
            <AccordionDetails>
              <g>Do not fear, you may still have a chance at glory. As long as the tournament is active, warriors are available to trade on OpenSea or LooksRare.</g>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#4a4a4a", color: "#e1e1e1" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              How much of the mint is allocated to the pot?
            </AccordionSummary>
            <AccordionDetails>
              <g>70% of the total mint is allocated to the pot, while the remaining 30% is distributed among the team for development, support, and expenses.</g>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#4a4a4a", color: "#e1e1e1" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              Roadmap?
            </AccordionSummary>
            <AccordionDetails>
              <g>Unlike most P2E games, Non-fungible Fighting Tokens has a finite end date. Once the tournament ends, funds are distributed to the winners and gameplay ceases… unless it doesn’t?</g>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#4a4a4a", color: "#e1e1e1" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              What is the max token supply?
            </AccordionSummary>
            <AccordionDetails>
              <g>The maximum number of warriors available is 8,192. In a perfect world, the tournament style bracket generates matchups until only 1 warrior remains. 2^13 = 8,192.</g>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </StyledGrid>
  )
}
