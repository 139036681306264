import React from 'react'
import styled from "styled-components";
import { Grid } from '@mui/material';

const StyledGrid = styled('div')(({ theme }) => ({
    height: "100%",
    width: "90%",
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '10%',
    color: theme.palette.typography.white,
    "g": { color: theme.palette.typography.gold},
    "gr": { color: theme.palette.typography.grey},
    "w": { color: theme.palette.typography.white},
  }))

const index = () => {
  return (
    <StyledGrid>
        <Grid Container>
            <Grid container item style={{fontSize: "30px", paddingTop: "10%", justifyContent: "center"}}>
                <w>Coming soon.</w>
            </Grid>
        </Grid>
    </StyledGrid>
  )
}

export default index