import '@rainbow-me/rainbowkit/styles.css';
import {
    apiProvider,
    configureChains,
    getDefaultWallets,
    darkTheme
} from '@rainbow-me/rainbowkit';
import { chain, createClient } from 'wagmi';
import merge from 'lodash.merge';

export const { chains, provider } = configureChains(
    [chain.mainnet],
    [
        apiProvider.alchemy(process.env.ALCHEMY_ID),
        apiProvider.fallback()
    ]
);

const { connectors } = getDefaultWallets({
    appName: 'My RainbowKit App',
    chains
});

export const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
});
export const myTheme = merge(darkTheme({ fontStack: 'system', borderRadius: 'small' }), {
    colors: {
        accentColor: '#4a4a4a',
    },
    fonts: {
        body: "FontC"
    } 
});
