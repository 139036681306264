import * as React from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import TwitterIcon from '@mui/icons-material/Twitter';
import { ReactComponent as Discord } from '../files/discord.svg';
import Logo from '../files/Logo-noBackground.png';
import AppBar from '@mui/material/AppBar';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import styled from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";

const pages = ['home', 'leaderboard', 'fight'];
const StyledLink = styled(Link)(({ theme }) => ({
    cursor: "pointer",
    textDecoration: "none",
    fontSize: "1.8em",
    color: theme.palette.typography.white,
    "&:hover": {
        color: theme.palette.typography.gold,
    },
    textTransform: "capitalize",
}))

const StyledDrawerLink = styled(Link)(({ theme }) => ({
    "&:hover": {
        color: theme.palette.typography.gold,
    },
    color: "white",
    textTransform: "capitalize",
}))

const StyledTwitter = styled(TwitterIcon)(({ theme }) => ({
    ":hover": { color: theme.palette.typography.gold },
    color: theme.palette.typography.white,
}))


const StyledDiscord = styled(Discord)(({ theme }) => ({
    "path": { fill: theme.palette.typography.white },
    "g:hover": { fill: theme.palette.typography.gold },
    "path:hover": { fill: theme.palette.typography.gold },
    "img:hover": { fill: theme.palette.typography.gold },
    height: "1em",
    width: "1em",
    color: theme.palette.typography.white,
}))

const ResponsiveAppBar = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static">
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <Grid container maxWidth="95%" margin="auto">
                        <Grid container item xs={3} direction="row" sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} style={{ justifyContent: 'left', marginRight: "unset" }}>
                            <Link to="/">
                                <img src={Logo} alt="logo" height={60} />
                            </Link>
                        </Grid>
                        <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                    "ul": {
                                        background: "#4a4a4a",
                                    }
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                                        <StyledDrawerLink to={page === "home" ? "/": page}>{page} </StyledDrawerLink>
                                    </MenuItem>
                                ))}
                            </Menu>
                            <Grid item sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
                                <Link to="/">
                                    <img src={Logo} alt="logo" height={60} />
                                </Link>
                            </Grid>
                        </Box>
                        <Grid container item xs={6} direction="row" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Grid item style={{ textAlign: "center", margin: "auto" }}>
                                    <StyledLink to={page === "home" ? "/": page}>{page}</StyledLink>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container item xs float="right" direction="row" sx={{ flexGrow: 1 }} style={{ textAlign: "center", justifyContent: 'right', margin: "auto" }}>
                            <IconButton aria-label="twitter" href="https://twitter.com/FightingTokens">
                                <StyledTwitter />
                            </IconButton>
                            <IconButton aria-label="discord" href="https://discord.gg/xqxHkraKZq">
                                <StyledDiscord />
                            </IconButton>
                            <ConnectButton chainStatus="none" showBalance={false} accountStatus={{ smallScreen: 'address', largeScreen: 'full' }} />
                        </Grid>
                    </Grid>
                </Toolbar>
            </Container>
        </AppBar >
    );
};
export default ResponsiveAppBar;
