import React, { useState } from 'react'
import styled from "styled-components";
import { Grid, ListItem, List, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledGrid = styled('div')(({ theme, desktopView }) => ({
  height: "85%",
  width: "90%",
  overflow: "auto",
  "::-webkit-scrollbar": {
    display: "none",
  },
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: '5%',
  paddingBottom: desktopView ? "none" : "10%",
  color: theme.palette.typography.white,
  "nested": { paddingLeft: theme.spacing(4) },
  ".title": {
    color: theme.palette.typography.white,
    fontSize: desktopView ? "2.5em" : "1.7em",
    fontWeight: "500",
    textAlign: "center"
  },
  ".description": {
    color: theme.palette.typography.grey,
    fontSize: desktopView ? "1.25em" : "0.9em", paddingTop: '2.5%', width: "80%", margin: "auto"
  },
  ".button": {
    fontSize: desktopView ? "1.5em" : "1.0em", border: "2px solid #e1e1e1", margin: "auto", textAlign: "center",
    color: theme.palette.typography.gold
  },
  ".closed": {
    color: "#ED2939", fontSize: desktopView ? "1em" : "0.8em", margin: "auto"
  }
}))

const StyledNumber = styled('text')(({ theme }) => ({
  color: theme.palette.typography.gold
}))

const StyledClosed = styled.h1`
  animation: shake 1s infinite;
@keyframes shake {
0%  { transform: translate(2px, 1px)   rotate(0deg);  }
10% { transform: translate(-1px, -2px) rotate(-2deg); }
20% { transform: translate(-3px, 0px)  rotate(3deg);  }
30% { transform: translate(0px, 2px)   rotate(0deg);  }
40% { transform: translate(1px, -1px)  rotate(1deg);  }
50% { transform: translate(-1px, 2px)  rotate(-1deg); }
60% { transform: translate(-3px, 1px)  rotate(0deg);  }
70% { transform: translate(2px, 1px)   rotate(-2deg); }
80% { transform: translate(-1px, -1px) rotate(4deg);  }
90% { transform: translate(2px, 2px)   rotate(0deg);  }
100%{ transform: translate(1px, -2px)  rotate(-1deg); }
}

`

function Fight() {
  const desktopView = useMediaQuery('(min-width:600px)');
  const [shake, setShake] = useState(false);

  const animate = () => {
    // Button begins to shake
    setShake(true);

    // Buttons stops to shake after 2 seconds
    setTimeout(() => setShake(false), 2000);

  }
  return (
    <StyledGrid desktopView={desktopView}>
      <Grid Container>
        <Grid container item direction="column">
          <Grid item className="title" spacing={1}>
            What are the fight mechanics?
          </Grid>
          <Grid item className="description" spacing={1}>
            <List>
              <ListItem>
                <gr><StyledNumber>-</StyledNumber> All warriors start with 100 health points.</gr>
              </ListItem>
              <ListItem>
                <gr><StyledNumber>-</StyledNumber> Players have 10 seconds to decide between one of three moves. Damage is calculated based off your opponent's move:</gr>
              </ListItem>
              <ListItem>
                <nested><gr><StyledNumber>-</StyledNumber> Stab - weak against counter, effective against stab, powerful  against slash.</gr></nested>
              </ListItem>
              <ListItem>
                <nested><gr><StyledNumber>-</StyledNumber> Slash - weak against stab, effective against Slash, powerful against counter.</gr></nested>
              </ListItem>
              <ListItem>
                <nested><gr><StyledNumber>-</StyledNumber> Counter - weak against slash, effective against counter, powerful against stab.</gr></nested>
              </ListItem>
              <ListItem>
                <gr><StyledNumber>-</StyledNumber> If a player does not choose a move within 10 seconds, the computer randomly generates a move and the warrior is penalized health point(s).</gr>
              </ListItem>
              <ListItem>
                <gr><StyledNumber>-</StyledNumber> If both warriors reach 0 health points at the same time, the fight goes to sudden death with 30 health points added to each warrior.</gr>
              </ListItem>
              <ListItem>
                <gr><StyledNumber>-</StyledNumber> Historical statistics and moves of opponents past fights are available to reference.</gr>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid container item style={{ paddingTop: "5%" }}>
          <Button variant="outlined" className={shake ? 'shake button' : "button"} onClick={animate}>
            <text>Join Fight</text>
          </Button>
        </Grid>
        <Grid container item style={{ paddingTop: "1%", margin: "auto" }}>
          {shake ? <StyledClosed className="closed"> Battlefield is currently closed </StyledClosed> : <h1 className="closed"> Battlefield is currently closed </h1>}
        </Grid>
      </Grid>
    </StyledGrid>
  );
}

export default Fight;