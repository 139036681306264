import React from 'react'
import styled from "styled-components";
import { Grid, Link } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import WinPhoto from "../../../files/team-win.png";
import KennyPhoto from "../../../files/team-kenny.png";
import AustinPhoto from "../../../files/team-austin.png";


const StyledTeam = styled('div')(({ theme, desktopView }) => ({
  color: theme.palette.typography.white,
  width: "90%",
  paddingBottom: desktopView ? "7.5%" : "7.5%",
  marginLeft: 'auto',
  marginRight: 'auto',
  "p": { textAlign: 'center', paddingTop: "8%", fontSize: desktopView ? "2.5em" : "1.7em", fontWeight: "500" },
  "img": { borderRadius: "48%", width: "100%", height: "100%" },
  "gr": { color: theme.palette.typography.grey },
  "w": { color: theme.palette.typography.white },
  ".avatar": {
    marginLeft: "auto", marginRight: "auto", height: desktopView ? "60%": "50%", paddingRight: desktopView ? "10%": "15%", paddingLeft: desktopView ? "0%": "10%"
  },
  ".handle": {
    marginLeft: "auto", marginRight: "auto", fontSize: desktopView ? "1.75em" : "1.1em", paddingTop: "5%"
  },
  ".name": {
    marginLeft: "auto", marginRight: "auto", fontSize: desktopView ? "1.25em" : "0.9em", paddingTop: "2.5%", color: theme.palette.typography.gold,
  },
  ".bio": {
    marginLeft: "10%", marginRight: "10%", fontSize: desktopView ? "1.25em" : "0.9em", textAlign: "center", paddingTop: "5%", color: theme.palette.typography.grey, 
  }
}))

export const Team = () => {
  const desktopView = useMediaQuery('(min-width:600px)');
  return (
    <StyledTeam desktopView={desktopView}>
      <p>Non-Fungible Fighting Founders </p>
      <Grid container direction={desktopView ? "row" : "column"} spacing={1} style={{ marginLeft: "unset" }}>
        <Grid item xs={4} container direction="column" style={{ padding: "unset"}}>
          {desktopView ? ( <Grid item xs className="avatar"> 
            <img src={WinPhoto} alt="winston-icon" />
          </Grid> ) : (
          <Grid item className="avatar"> 
            <img src={WinPhoto} alt="winston-icon" />
          </Grid> ) }
          <Grid item className="handle">
            <Link href="https://twitter.com/Winston_Frick" underline="hover" color="#e1e1e1">
              @Winston_Frick
            </Link>
          </Grid>
          <Grid item className="name">
            Winston Frick
          </Grid>
          {desktopView ? ( <Grid item xs className="bio"> 
            Born in the hills of Persepolis, this builder’s skills rival that of Hephaestus. As the most gifted craftsman in the land, he was chosen by the oracles to train in the forge of Meta™. His hammer will soon strike a new path for his army in the lands of Web 3.
          </Grid> ) : (
          <Grid item className="bio" marginBottom={"5%"}> 
            Born in the hills of Persepolis, this builder’s skills rival that of Hephaestus. As the most gifted craftsman in the land, he was chosen by the oracles to train in the forge of Meta™. His hammer will soon strike a new path for his army in the lands of Web 3.
          </Grid> ) }
        </Grid>
        <Grid item xs={4} container direction="column" style={{ padding: "unset" }}>
          {desktopView ? ( <Grid item xs className="avatar"> 
            <img src={KennyPhoto} alt="ken-icon" />
          </Grid> ) : (
          <Grid item className="avatar"> 
            <img src={KennyPhoto} alt="ken-icon" />
          </Grid> ) }
          <Grid item className="handle">
            <Link href="https://twitter.com/MintedKenny" underline="hover" color="#e1e1e1">
              @MintedKenny
            </Link>
          </Grid>
          <Grid item className="name">
            Kenneth MacDonald
          </Grid>
          {desktopView ? ( <Grid item xs className="bio"> 
            Once a struggling artist in the streets of Athens, this creative has risen to the rank of war councilman. A man of many trades, he now develops war strategy for his legion of soldiers and builds infrastructure within the city of Athens.
          </Grid> ) : (
          <Grid item className="bio" marginBottom={"5%"}> 
            Once a struggling artist in the streets of Athens, this creative has risen to the rank of war councilman. A man of many trades, he now develops war strategy for his legion of soldiers and builds infrastructure within the city of Athens.
          </Grid> ) }
        </Grid>
        <Grid item xs={4} container direction="column" style={{ padding: "unset" }}>
          {desktopView ? ( <Grid item xs className="avatar"> 
            <img src={AustinPhoto} alt="underwood-icon" />
          </Grid> ) : (
          <Grid item className="avatar"> 
            <img src={AustinPhoto} alt="underwood-icon" />
          </Grid> ) }
          <Grid item className="handle">
            <Link href="https://twitter.com/Cave_of_Plato" underline="hover" color="#e1e1e1">
              @Cave_of_Plato
            </Link>
          </Grid>
          <Grid item className="name">
            Austin Underwood
          </Grid>
          {desktopView ? ( <Grid item xs className="bio"> 
            Trained as a young boy in the fighting pits of Sparta, this war hero has backed down armies with a handful of men by his side. His glory and ethos have earned him command of all Spartan foot soldiers.
          </Grid> ) : (
          <Grid item className="bio" marginBottom={"5%"}> 
            Trained as a young boy in the fighting pits of Sparta, this war hero has backed down armies with a handful of men by his side. His glory and ethos have earned him command of all Spartan foot soldiers.
          </Grid> ) }
        </Grid>
      </Grid>
    </StyledTeam >
  )
}