import React from 'react'
import styled from "styled-components";
import cover from "../../../files/Cover.png"
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledLanding = styled('div')(({ theme, desktopView }) => ({
  height: "90%",
  width: "100%",
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: '5%',
  ".title": {
    fontSize: desktopView ? "3.5em" : "2.5em",
    textAlign: "center",
    fontWeight: "500",
    color: theme.palette.typography.white
  },
  ".subtitle": {
    padding: "2%",
    fontSize: desktopView ? "2.5em" : "1.7em",
    textAlign: "center",
    color: theme.palette.typography.grey,
  }
}))

const StyledImage = styled.img`
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  width: 100%;
`

export const HomeLanding = () => {
  const desktopView = useMediaQuery('(min-width:600px)');
  return (
    <div>
      <StyledLanding desktopView={desktopView}>
        <div class="title"> Non-Fungible Fighting Tokens </div>
        <div class="subtitle" > Chapter 1: Fight for Philotimo </div>
        <StyledImage src={cover} />
      </StyledLanding>
    </div>
  )
}
