import React from 'react'
import styled from "styled-components";
import logo from "../../../files/LogoAlt-NoBackground.gif";
import { Grid, ListItem, List } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledGrid = styled('div')(({ theme, desktopView }) => ({
  height: "100%",
  width: "90%",
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: '7.5%',
  paddingBottom: '7.5%',
  color: theme.palette.typography.white,
  ".pot-allocations": { color: theme.palette.typography.grey, fontSize: desktopView ? "1.5em" : "1em", justifyContent: 'center', paddingTop: '5%', paddingBottom: '7.5%' },
  ".hype": { color: theme.palette.typography.whiteDark, fontSize: desktopView ? "1.75em" : "1.1em", marginBottom: desktopView ? "2.5%" : "5%" },
  textAlign: "center",
}))

const StyledImage = styled.img`
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  max-width: 90%;
`

export const WhatNFFT = () => {
  const desktopView = useMediaQuery('(min-width:600px)');
  return (
    <div>
      <StyledGrid desktopView={desktopView}>
        <Grid container>
          <Grid container item direction="column" style={{ marginLeft: '10%', marginRight: '10%' }}>
            <Grid item class="hype">
              Enter brave warrior to the tournament of Non-Fungible Fighting Tokens. 8,192 warriors will fight, but only 1 will be crowned champion. Will it be you?
            </Grid>
            <Grid item class="hype">
              Test your might each week against a random opponent to secure a spot in the next round. As you progress, your suit of armor will become more valuable and those looking to enter may try and offer payment. But will you risk the spoils of war?
            </Grid>
            <Grid item class="hype" style={{ marginBottom: "unset"}}>
              To be champion means more than mere glory among men. 70% of mint funds go directly to the pot.
            </Grid>
          </Grid>
          <Grid container className="pot-allocations">
            <Grid item>
              <List>
                <ListItem>
                  1st Place:
                </ListItem>
                <ListItem>
                  2nd Place:
                </ListItem>
                <ListItem>
                  T-3rd (2) Place:
                </ListItem>
                <ListItem>
                  T-4th (4) Place:
                </ListItem>
              </List>
            </Grid>
            <Grid item>
              <List>
                <ListItem>
                  45% of Pot
                </ListItem>
                <ListItem>
                  25% of Pot
                </ListItem>
                <ListItem>
                  10% of Pot
                </ListItem>
                <ListItem>
                  2.5% of Pot
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <StyledImage src={logo} />
        </Grid>
      </StyledGrid>
    </div>
  )
}