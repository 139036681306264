import React from 'react'
import styled from "styled-components";
import { Grid, ListItem, List } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledGrid = styled('div')(({ theme, desktopView }) => ({
  height: "100%",
  width: "90%",
  marginLeft: 'auto',
  marginRight: 'auto',
  ".title": {
    color: theme.palette.typography.white,
    fontSize: desktopView ? "2.5em" : "1.7em",
    fontWeight: "500",
    textAlign: 'center'
  },
  ".rules": {
    color: theme.palette.typography.grey,
    fontSize: desktopView ? "1.5em" : "1.0em",
    paddingTop: '5%'
  }
}))

const StyledNumber = styled('text')(({ theme }) => ({
  color: theme.palette.typography.gold
}))

export const GameMechanics = () => {
  const desktopView = useMediaQuery('(min-width:600px)');
  return (
    <div>
      <StyledGrid desktopView={desktopView}>
        <Grid container direction="column">
          <Grid item spacing={1} className="title">
            How Does the Game Work?
          </Grid>
          <Grid item spacing={1} className="rules">
            <List>
              <ListItem>
                <text><StyledNumber>1.</StyledNumber> Fights occur once a week during a 3 hour window.</text>
              </ListItem>
              <ListItem>
                <text><StyledNumber>2.</StyledNumber> Once the window opens, players can send their warriors to the battlefield where warriors are randomly paired to fight.</text>
              </ListItem>
              <ListItem>
                <text><StyledNumber>3.</StyledNumber> Winning warriors remain victorious and are eligible for fights the next week, while losing warriors immediately perish and are eliminated from the tournament altogether.</text>
              </ListItem>
              <ListItem>
                <text><StyledNumber>4.</StyledNumber> If a warrior fails to enter the battlefield within the allotted time, their fate is automatically determined with only a 30% chance of moving to the next round.</text>
              </ListItem>
              <ListItem>
                <text><StyledNumber>5.</StyledNumber> Players can hold multiple warriors per wallet, although only one warrior can fight at a time. Players are able to fight with multiple warriors within the fighting window.</text>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </StyledGrid>
    </div>
  )
}
