import React from 'react'
import { HomeLanding } from './homeLanding/homeLanding'
import { WhatNFFT } from './whatNFFT/whatNFFT';
import { GameMechanics } from './gameMechanics/gameMechanics';
import { Team } from './team/team';
import { FAQ } from './FAQ/FAQ';
import { PlaySound } from '../../components/PlaySound';

const index = () => {
  return (
    <>
      <HomeLanding />
      <WhatNFFT />
      <GameMechanics />
      <Team />
      <FAQ />
      <PlaySound />
    </>
  )
}

export default index