import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#191b1d",
      light: "#7D7D7D",
      dark: "#4a4a4a",
    },
    secondary: {
      main: '#A9A9A9',
    },
    otherColor: {
      main: "#f3bc02"
    },
    typography: {
      fontFamily: "MyFont",
      white: "#e1e1e1",
      whiteDark: "#b4b4b4",
      grey: "#909090",
      gold: "#f2bc00",
    },
  }
})

