import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ResponsiveAppBar from "./components/ResponsiveNavbar";
import Home from "./pages/home/index";
import Fight from "./pages/fight/index";
import Leaderboard from "./pages/leaderboard/index";
import {
  CssBaseline
} from "@material-ui/core";
import styled from "styled-components";

const Content = styled.div`
  border: 1px solid #000;
  background-color: #191b1d;
  height: 100vh;
  min-height: -webkit-fill-available;
  width: inherit;
  overflow-y:auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */ 
`;

const App = () => {
  return (
    <CssBaseline>
      <Router>
        <ResponsiveAppBar />
        <Content>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/fight" element={<Fight />} />
          </Routes>
        </Content>
      </Router>
    </CssBaseline >
  );
}

export default App;
